import { styled } from '@mui/material/styles';
import { Navigate } from "react-router-dom";

import {
  TableRow, TableCell, tableCellClasses,
} from '@mui/material';
import { STORAGE_KEYS } from '../../../../../helpers/helpers';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    justifyContent: "center"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    justifyContent: "center",
    alignItems: "center"
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

/**
 * If the user is logged in, render the children, otherwise redirect to the login page
 * @returns The children of the PrivateRoute component.
 */
export const PrivateRoute = ({ children }) => {
  const auth = localStorage.getItem(STORAGE_KEYS.LOGGED_IN);
  return auth === "true" ? children : <Navigate to="/login" replace />;
}

export const MAILBOXES_DIC_ROUTES = {
  "iwtcustomerservice@solenis.com": "NA",
  "papcustomerservice@solenis.com": "NA",
  "napool@solenis.com": "POOLS",
  "cspoolsbrasil@solenis.com": "POOLS",
  "juanusugavao@hotmail.com": "NA"
}
