import _ from 'lodash';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import moment from 'moment';
import * as XLSX from 'xlsx';

import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import {
  TableContainer, Paper, Table, TableHead, CircularProgress,
  TableRow, Grid, TableBody, Pagination, IconButton, Menu, MenuItem,
  TextField, InputAdornment, Button, Divider, Tooltip, Snackbar, Alert, AlertTitle,
  Badge
} from '@mui/material';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import RefreshIcon from '@mui/icons-material/Refresh';

import {
  auditsFilters, downloadAuditReport,
  //getMailboxesData
} from '../../../../APICalls';

import { StyledTableCell, StyledTableRow, MAILBOXES_DIC_ROUTES } from '../DetailsPage/utils/utils';
import { ModalCurrPrevValues } from './ModalCurrPrevValues';
import { GenericModalCurrPrevValues } from './GenericModalCurrPrevValues';
import { STORAGE_KEYS } from '../../../../helpers/helpers';
import { parseDateToUserTimezone } from '../../../../utils/utils';

const PO_NUMBER_NAMES = ["Purchase order #", "PO Number", "Purchase Order", "Purchase Order #"];

const LAST_DAYS_FILTER = 7;

const getStartDate = () => {
  // Get the current date
  const currentDate = new Date();

  // Calculate the date LAST_DAYS_FILTER days ago
  const sevenDaysAgo = new Date(currentDate);
  sevenDaysAgo.setDate(currentDate.getDate() - LAST_DAYS_FILTER);

  // Get the first date (LAST_DAYS_FILTER days ago)
  const firstDate = sevenDaysAgo.toISOString().slice(0, 10);

  return firstDate;
};

const getStartDateRaw = () => {
  // Get the current date
  const currentDate = new Date();

  // Calculate the date LAST_DAYS_FILTER days ago
  const sevenDaysAgo = new Date(currentDate);
  sevenDaysAgo.setDate(currentDate.getDate() - LAST_DAYS_FILTER);

  return sevenDaysAgo;
};

const AuditComponent = () => {
  const { state } = useLocation();

  const checkTid = state && state.tid
    ? state.tid
    : "ALL";

  const appliedFiltersValuesState = state && state.appliedFiltersValues
    ? state.appliedFiltersValues
    : '';

  const appliedFiltersState = state && state.appliedFilters
    ? state.appliedFilters
    : '';
  const navigate = useNavigate();
  const [auditData, setAuditData] = useState(null);
  const [currentElementKeys, setCurrentElementKeys] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [tokenValue, setTokenValue] = useState(null);

  const [openUsernameFilter, setOpenUsernameFilter] = useState(false);
  const [usernameAnchorElement, setUsernameAnchorElement] = useState(null);
  const [openTIDFilter, setOpenTIDFilter] = useState(false);
  const [TIDAnchorElement, setTIDAnchorElement] = useState(null);
  const [openActionFilter, setOpenActionFilter] = useState(false);
  const [actionAnchorElement, setActionAnchorElement] = useState(null);
  const [openCurrentValueFilter, setOpenCurrentValueFilter] = useState(false);
  const [currentValueAnchorElement, setCurrentValueAnchorElement] = useState(null);
  const [dateAnchorElement, setDateAnchorElement] = useState(null);
  const [mailboxesAnchorElement, setMailboxesAnchorElement] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [openModalView, setOpenModalView] = useState(null);
  const [openGenericModalView, setOpenGenericModalView] = useState(null);
  const [genericModalData, setGenericModalData] = useState(null);
  const [POJSON, setPOJSON] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isCurrentValueGenericModal, setIsCurrentValueGenericModal] = useState(null);
  const [totalKeys, setTotalKeys] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [copiedClipboard, setCopiedClipboard] = useState(false);
  const [startDate, setStartDate] = useState(getStartDateRaw());
  const [endDate, setEndDate] = useState(new Date());
  const [openDateFilter, setOpenDateFilter] = useState(false);
  const [openMailboxesFilter, setOpenMailboxesFilter] = useState(false);
  const [mailboxesOptions, setMailboxesOptions] = useState([]);
  const [selectMenuHeight, setSelectMenuHeight] = useState('100px');
  const [isLoading, setIsLoading] = useState(false);
  const [mailboxesDict, setMailboxesDict] = useState({});
  const [transactionId, setTransactionId] = useState('');

  const [appliedFilters, setAppliedFilters] = useState({
    "startDate": false, "endDate": false, "mailbox": false,
    "transactionId": false, "workflowId": false, "userId": false,
    "username": false, "clientId": false, "action": false, "prevValue": false,
    "currentValue": false
  });

  const [appliedFiltersValues, setAppliedFiltersValues] = useState({
    "startDate": getStartDate(), "endDate": "ALL", "mailbox": "ALL",
    "transactionId": checkTid, "workflowId": "ALL", "userId": "ALL",
    "username": "ALL", "clientId": "ALL", "action": "ALL", "prevValue": "ALL",
    "currentValue": "ALL"
  });

  const checkIfLoading = () => {
    if (isLoading) {
      return (
        <Grid
          container
          justifyContent="center"
          paddingTop="3%"
        >
          <Grid item xs={12}>
            <CircularProgress color="info" />
          </Grid>
          <Grid item xs={12}>
            <h2>
              Loading data, please wait...
            </h2>
          </Grid>
        </Grid>
      )
    } else if (_.isEmpty(filteredData)) {
      return (
        <Grid
          justifyContent="center"
          alignItems="stretch"
          textAlign="center"
        >
          <h2>
            There's no data to show.
          </h2>
        </Grid>
      )
    }
  }

  const handleOpenDateFilter = (event) => {
    setDateAnchorElement(event.currentTarget);
    setOpenDateFilter(true);
  };

  const handleCloseDateFilter = () => {
    setDateAnchorElement(null);
    setOpenDateFilter(false);
  };

  const handleCloseModalView = () => {
    setOpenModalView(false);
    setPOJSON(null)
  }

  const handleOpenModalView = () => {
    setOpenModalView(true);
  }

  const handleCloseGenericModalView = () => {
    setOpenGenericModalView(false);
    setGenericModalData(null);
  }

  const handleOpenGenericModalView = () => {
    setOpenGenericModalView(true);
  }

  const handleCloseCopiedValue = () => {
    setCopiedClipboard(false);
  }

  const handleOpenMailboxesFilter = (event) => {
    setMailboxesAnchorElement(event.currentTarget);
    setOpenMailboxesFilter(true);
  };

  const handleCloseMailboxesFilter = () => {
    setMailboxesAnchorElement(null);
    setOpenMailboxesFilter(false);
  };

  const handleOpenTIDValue = (TID) => () => {
    navigator.clipboard.writeText(TID)
      .then(() => {
        setCopiedClipboard(true);
      })
      .catch((error) => {
        setCopiedClipboard(false);
      });
  }

  const checkFilterValue = (validationText) => {
    return (validationText === "ALL"
      ? ""
      : validationText
    )
  }

  const checkOnMenuOpen = () => {
    setSelectMenuHeight('350px');
  }

  const checkOnMenuClose = () => {
    setSelectMenuHeight('90px');
  }

  const handleClearFilter = (keyFilter) => () => {
    setAppliedFilters({
      ...appliedFilters,
      [keyFilter]: false,
    });
    setAppliedFiltersValues({
      ...appliedFiltersValues,
      [keyFilter]: "ALL",
    });
  }

  const handleOnChangeSelect = (selectValue) => {
    const { value } = selectValue
    if (_.isEmpty(selectValue)) {
      setAppliedFilters({
        ...appliedFilters,
        "mailbox": false,
      });

      setAppliedFiltersValues({
        ...appliedFiltersValues,
        "mailbox": "ALL"
      });
      return;
    }
    setAppliedFilters({
      ...appliedFilters,
      "mailbox": true,
    });

    setAppliedFiltersValues({
      ...appliedFiltersValues,
      "mailbox": value
    });
  }

  const formatDateStart = (date) => {
    const adjustedDate = new Date(date);
    const day = adjustedDate.getDate();
    const adjustedDay = day < 10
      ? `0${day}`
      : `${day}`
    const month = adjustedDate.getMonth() + 1;
    const adjustedMonth = month < 10
      ? `0${month}`
      : `${month}`
    const year = adjustedDate.getFullYear();
    const adjustedCase = `${year}-${adjustedMonth}-${adjustedDay}`
    handleStartDateFilter(adjustedCase);
    setStartDate(date)
  }

  const formatDateEnd = (date) => {
    const adjustedDate = new Date(date);
    const day = adjustedDate.getDate();
    const adjustedDay = day < 10
      ? `0${day}`
      : `${day}`
    const month = adjustedDate.getMonth() + 1;
    const adjustedMonth = month < 10
      ? `0${month}`
      : `${month}`
    const year = adjustedDate.getFullYear();
    const adjustedCase = `${year}-${adjustedMonth}-${adjustedDay}`
    handleEndDateFilter(adjustedCase);
    setEndDate(date)
  }

  const handleDownloadAuditReport = () => {
    const formated_start_date = new Date(startDate).toISOString().slice(0, 10);
    const formated_end_date = new Date(endDate).toISOString().slice(0, 10);

    downloadAuditReport(formated_start_date, formated_end_date, appliedFiltersValues['mailbox'], tokenValue).then(arrayOfArrays => {
      const workbook = XLSX.utils.book_new();
      const splitArray = arrayOfArrays.map(row => row[0].split(',').map(cell => cell.trim()));
      const modifiedWorksheet = XLSX.utils.aoa_to_sheet(splitArray);
      XLSX.utils.book_append_sheet(workbook, modifiedWorksheet, 'Sheet1');
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });
      const href = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${formated_start_date}--${formated_end_date}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

  }


  const removeSingleQuotaInsideDoubleQuote = (input) => {
    /*
     This function removes cases like "David O'CONNOR" and convert it into 'David OCONNOR',
     looks for ' inside two ". it will ignore the ' and at the end of the property will change the "" by '
    */
    let output = '';
    let double_quote_found = false;
    let left_double_quote = -1
    let found_single_quote_inside_double_quote = false;

    for (let i = 0; i < input.length; i++) {
      const char = input[i];
      if (char === '"') {
        double_quote_found = !double_quote_found;
        if (double_quote_found) {
          left_double_quote = i;
        } else {
          if (found_single_quote_inside_double_quote) {
            output = output.slice(0, left_double_quote) + "'" + output.slice(left_double_quote + 1, i) + "'"
            found_single_quote_inside_double_quote = false
            continue
          }
        }
      }
      if (char === "'" && double_quote_found) {
        found_single_quote_inside_double_quote = true
        continue;
      }
      output += char;
    }
    return output;
  }

  const processValuesToJSON = (value) => {
    const replacedDoubleQuotes = removeSingleQuotaInsideDoubleQuote(value).replace(/"/g, "");
    const parsedValue = replacedDoubleQuotes.replace(/'/g, '"').replace(/(?<=\w)"(?=\w)/g, "'")
    const fixedValueFalse = parsedValue.replace(/False/g, "false");
    const fixedValueTrue = fixedValueFalse.replace(/True/g, "true");
    const adjustBadEscapedValue = fixedValueTrue.replace(/xa0/g, "");
    const replacedBackslash = adjustBadEscapedValue.replace(/\\/g, "");
    const adjustedNone = replacedBackslash.replace(/None/g, "false");
    const parsedData = JSON.parse(adjustedNone);
    return parsedData
  }

  const filterValues = (value, metadata) => {
    const parsedData = processValuesToJSON(value)
    const fixedMetadata = processValuesToJSON(metadata)
    return [parsedData, fixedMetadata];
  }

  const createPrevValuesColumn = (currValues, prevValues, metadata) => {
    for (let i in currValues.outputs) {
      for (let j in currValues.outputs[i].data) {
        try {
          for (let attr in currValues.outputs[i].data[j] ?? []) {
            if (attr !== "Data Variables" && prevValues.outputs[i].data[j].hasOwnProperty(attr)) {
              currValues.outputs[i].data[j]["Previous " + attr] = prevValues.outputs[i].data[j][attr];
            }
          }
        } catch {

        }
      }
    }
    for (let i in metadata.changes ?? []) {
      for (let j in metadata.changes[i].deleted_columns ?? []) {
        for (let attr in metadata.changes[i].deleted_columns[j]) {
          try {
            currValues.outputs[i].data[j][`Deleted ${attr}`] = metadata.changes[i].deleted_columns[j][attr]
          } catch {

          }

        }
      }
    }
    return currValues
  }

  const handlePOJSON = (currValues, index, metadata, prevValues, transaction_id) => () => {
    setCurrentIndex(index)
    setTransactionId(transaction_id)
    const [currParsedData, fixedMetadata] = filterValues(currValues, metadata);
    const [prevParsedData] = filterValues(prevValues, metadata);
    const dataWithPrevColumn = createPrevValuesColumn(currParsedData, prevParsedData, fixedMetadata);
    const { outputs } = dataWithPrevColumn;
    const keys = Object.keys(outputs);
    setTotalKeys(keys);
    setPOJSON(dataWithPrevColumn);
    setMetadata(fixedMetadata);
    handleOpenModalView();
  }

  const handleGenericModal = (value, checkingCurrentValue) => () => {
    setIsCurrentValueGenericModal(checkingCurrentValue);
    setGenericModalData(value);
    handleOpenGenericModalView();
  }

  const setAPICallAudits = async (tokenData, checkTid, pageValue = 1, sizeValue = 25) => {
    let { startDate } = appliedFiltersValues
    setIsLoading(true);
    if (checkTid !== "ALL") {
      const currentDate = new Date();
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() - 1000);
      startDate = date.toISOString().slice(0, 10);
    }
    const getAuditData = await auditsFilters(tokenData, checkTid, "ALL", "ALL", "ALL", "ALL", "ALL", "ALL", "ALL", startDate, "ALL", "ALL", pageValue, sizeValue);
    setIsLoading(false);
    const { status, data } = getAuditData;
    if (status === 401) {
      navigate("/login");
      return;
    }

    if (data) {
      const { items, total, page, size } = data
      const testElement = items?.[0] ? items[0] : [];
      const tableKeys = Object.keys(testElement);
      setTotalPages(Math.ceil(total / size));
      setCurrentPage(page);
      setAuditData(items);
      setFilteredData(items);
      setCurrentElementKeys(tableKeys.filter(item => item !== "prevValue"));
    } else {
      setTotalPages(1);
      setCurrentPage(1);
      setFilteredData([]);
    }

  }

  const handleAPICallPage = async (actualPage) => {
    setIsLoading(true);
    const {
      transactionId, workflowId, userId, username, clientId,
      action, prevValue, currentValue, startDate, endDate
    } = appliedFiltersValues
    const validateCheck = await auditsFilters(
      tokenValue, transactionId, workflowId, userId, username, clientId,
      action, prevValue, currentValue, startDate, endDate, appliedFiltersValues['mailbox'], actualPage, 25
    )
    setIsLoading(false);
    if (validateCheck.detail) {
      setFilteredData([]);
      return;
    }
    const { data } = validateCheck;
    if (data) {
      const { items, page, size, total } = data;
      setTotalPages(Math.ceil(total / size));
      setCurrentPage(page);
      setFilteredData(items);
    } else {
      setTotalPages(1);
      setCurrentPage(1);
      setFilteredData([]);
    }

  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    handleAPICallPage(value);
  }

  const handleOpenUsernameFilter = (event) => {
    setUsernameAnchorElement(event.currentTarget);
    setOpenUsernameFilter(true);
  };

  const handleCloseUsernameFilter = () => {
    setUsernameAnchorElement(null);
    setOpenUsernameFilter(false);
  };

  const handleOpenTIDFilter = (event) => {
    setTIDAnchorElement(event.currentTarget);
    setOpenTIDFilter(true);
  };

  const handleCloseTIDFilter = () => {
    setTIDAnchorElement(null);
    setOpenTIDFilter(false);
  };


  const handleOpenActionFilter = (event) => {
    setActionAnchorElement(event.currentTarget);
    setOpenActionFilter(true);
  };

  const handleCloseActionFilter = () => {
    setActionAnchorElement(null);
    setOpenActionFilter(false);
  };

  const handleOpenCurrentValueFilter = (event) => {
    setCurrentValueAnchorElement(event.currentTarget);
    setOpenCurrentValueFilter(true);
  };

  const handleCloseCurrentValueFilter = () => {
    setCurrentValueAnchorElement(null);
    setOpenCurrentValueFilter(false);
  };

  const handleStartDateFilter = (date) => {
    if (_.isEmpty(date)) {
      setAppliedFilters({
        ...appliedFilters,
        "createdDate": false,
      });
      setAppliedFiltersValues({
        ...appliedFiltersValues,
        "startDate": getStartDate(),
      });
      setCurrentPage(1)
      return;
    }
    setAppliedFilters({
      ...appliedFilters,
      "createdDate": true,
    });
    setAppliedFiltersValues({
      ...appliedFiltersValues,
      "startDate": date,
    });
    setCurrentPage(1)
  }

  const handleEndDateFilter = (date) => {
    if (_.isEmpty(date)) {
      setAppliedFilters({
        ...appliedFilters,
        "createdDate": false,
      });
      setAppliedFiltersValues({
        ...appliedFiltersValues,
        "endDate": "ALL",
      });
      setCurrentPage(1)
      return;
    }
    setAppliedFilters({
      ...appliedFilters,
      "createdDate": true,
    });
    setAppliedFiltersValues({
      ...appliedFiltersValues,
      "endDate": date,
    });
    setCurrentPage(1)
  }

  const handleFilteringUsername = (usernameValue) => {
    if (_.isEmpty(usernameValue)) {
      setAppliedFilters({
        ...appliedFilters,
        "username": false,
      });
      setAppliedFiltersValues({
        ...appliedFiltersValues,
        "username": "ALL"
      });
      return;
    }
    setAppliedFiltersValues({
      ...appliedFiltersValues,
      "username": usernameValue
    });
    setAppliedFilters({
      ...appliedFilters,
      "username": true,
    });
  }

  const handleFilteringAction = (actionValue) => () => {
    if (_.isEmpty(actionValue)) {
      setAppliedFilters({
        ...appliedFilters,
        "action": false,
      });
      setAppliedFiltersValues({
        ...appliedFiltersValues,
        "action": "ALL"
      });
      return;
    }
    setAppliedFiltersValues({
      ...appliedFiltersValues,
      "action": actionValue
    });
    setAppliedFilters({
      ...appliedFilters,
      "action": true,
    });
  }

  const handleFilteringTID = (tidValue) => {
    if (_.isEmpty(tidValue)) {
      setAppliedFilters({
        ...appliedFilters,
        "transactionId": false,
      });
      setAppliedFiltersValues({
        ...appliedFiltersValues,
        "transactionId": "ALL"
      });
      return;
    }
    setAppliedFiltersValues({
      ...appliedFiltersValues,
      "transactionId": tidValue
    });

    setAppliedFilters({
      ...appliedFilters,
      "transactionId": true,
    });
  }

  const handleFilteringCurrentValue = (actualValue) => {
    if (_.isEmpty(actualValue)) {
      setAppliedFilters({
        ...appliedFilters,
        "currentValue": false,
      });
      setAppliedFiltersValues({
        ...appliedFiltersValues,
        "currentValue": "ALL"
      });
      return;
    }
    setAppliedFiltersValues({
      ...appliedFiltersValues,
      "currentValue": actualValue
    });
    setAppliedFilters({
      ...appliedFilters,
      "currentValue": true,
    });
  }

  const refreshList = () => {
    handleAPIWithFiltersCall();
  }

  const handleAPIWithFiltersCall = async () => {
    const {
      transactionId, workflowId, userId, username, clientId,
      action, prevValue, currentValue, startDate, endDate
    } = appliedFiltersValues
    const currentPageValue = currentPage
      ? currentPage
      : 1;
    setIsLoading(true);
    if (tokenValue) {
      const validateCheck = await auditsFilters(
        tokenValue, transactionId, workflowId, userId, username, clientId,
        action, prevValue, currentValue, startDate, endDate, appliedFiltersValues['mailbox'], currentPageValue, 25
      )
      setIsLoading(false);
      if (validateCheck.detail) {
        setFilteredData([]);
        return;
      }
      const { data } = validateCheck;
      if (data) {
        const { items, page, size, total } = data;
        setTotalPages(Math.ceil(total / size));
        setCurrentPage(page);
        setFilteredData(items);
      } else {
        setTotalPages(1);
        setCurrentPage(1);
        setFilteredData([]);
      }
    }
  }

  const redirectWebPage = (transactionId, isPoOrder, mailbox) => () => {
    const currentMailbox = _.get(MAILBOXES_DIC_ROUTES, mailbox, '')
    const validateCurrentMailbox = currentMailbox ? currentMailbox : "NA";
    navigate(`/details/:${transactionId}/:1/:COMPLETED`, {
      state:
      {
        appliedFiltersValues: appliedFiltersValuesState,
        appliedFilters: appliedFiltersState
      }
    });
  }

  const checkFiltersObjValues = () => {
    return Object.values(appliedFilters).some(value => value === true);
  }

  const filtersListVisualization = () => {
    const filtersNames = Object.keys(appliedFilters);
    const appliedFiltersNames = filtersNames.filter((currentKey) => {
      return appliedFilters[currentKey];
    });
    return (appliedFiltersNames.map((current) => {
      return (
        <>
          <h4>
            {current}
            &nbsp;
            &nbsp;
          </h4>
        </>
      )
    })
    )
  }

  const checkFilters = (key) => {
    if (key === "transactionId") {
      return (
        <>
          <Grid xs={1}>
            <IconButton
              onClick={handleOpenTIDFilter}
              edge="end"
              color="primary"
            >
              <FilterAltIcon />
            </IconButton>
            <Menu
              anchorEl={TIDAnchorElement}
              open={openTIDFilter}
              onClose={handleCloseTIDFilter}
            >
              <MenuItem
                onClick={(event) => event.preventDefault()}
              >
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onInput={((event) => {
                    handleFilteringTID(event.target.value)
                  })}
                  label="TID Filter"
                  placeholder='Type and search TID'
                  variant="outlined"
                  value={checkFilterValue(appliedFiltersValues.transactionId)}
                >
                </TextField>
              </MenuItem>
            </Menu>
          </Grid>
        </>
      )
    }
    if (key === "createdDate") {
      return (
        <>
          <Grid item xs={8}>
            <IconButton
              onClick={handleOpenDateFilter}
              edge="end"
              color="primary"
            >
              <FilterAltIcon />
            </IconButton>
            <Menu
              anchorEl={dateAnchorElement}
              open={openDateFilter}
              onClose={handleCloseDateFilter}
              PaperProps={{
                style: {
                  height: "35%",
                  minWidth: "484px",
                  overflow: "auto"
                },
              }}
            >
              <Grid container justifyContent="space-around">
                <Grid item xs={6}>
                  <Grid container padding={1}>
                    <Grid item xs={12}>
                      <b>Start date:</b>
                    </Grid>
                    <Grid item xs={12}>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                          formatDateStart(date);
                          handleCloseDateFilter();
                        }
                        } />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container padding={1}>
                    <Grid item xs={12}>
                      <b>End date:</b>
                    </Grid>
                    <Grid item xs={12}>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => {
                          formatDateEnd(date);
                          handleCloseDateFilter();
                        }
                        } />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Menu>
          </Grid>
        </>
      )
    }
    if (key === "mailbox") {
      return (
        <Grid xs={6}>
          <IconButton
            onClick={handleOpenMailboxesFilter}
            edge="end"
            color="primary"
          >
            <FilterAltIcon />
          </IconButton>
          <Menu
            anchorEl={mailboxesAnchorElement}
            open={openMailboxesFilter}
            onClose={handleCloseMailboxesFilter}
            PaperProps={{
              style: {
                height: selectMenuHeight,
                width: '350px'
              },
            }}
          >
            <MenuItem
              onClick={(event) => event.preventDefault()}
              style={{ height: "100%" }}
            >
              &#8288;
              <Grid container>
                <Grid item xs={11}>
                  <Select
                    options={mailboxesOptions}
                    placeholder="Mailbox:"
                    onChange={handleOnChangeSelect}
                    style={{ width: '100%' }}
                    onMenuOpen={checkOnMenuOpen}
                    onMenuClose={checkOnMenuClose}
                    value={appliedFiltersValues['mailbox']
                      ? {
                        label: _.get(mailboxesDict, appliedFiltersValues['mailbox'], appliedFiltersValues['mailbox']),
                        value: appliedFiltersValues['mailbox']
                      }
                      : "ALL"}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={handleClearFilter("mailbox")}
                    edge="end"
                    color="error"
                  >
                    <ClearIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </MenuItem>
          </Menu>
        </Grid>
      )
    }
    if (key === "action") {
      return (
        <>
          <Grid item xs={8}>
            <IconButton
              onClick={handleOpenActionFilter}
              edge="end"
              color="primary"
            >
              <FilterAltIcon />
            </IconButton>
            <Menu
              anchorEl={actionAnchorElement}
              open={openActionFilter}
              onClose={handleCloseActionFilter}
            >
              <MenuItem
                onClick={handleFilteringAction("approve")}
              >
                <Grid
                  container
                  justifyItems="center"
                >
                  <TaskAltIcon />
                  Approve
                </Grid>
              </MenuItem>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem
                onClick={handleFilteringAction("modify")}
              >
                <Grid
                  container
                  justifyItems="center"
                >
                  <ModeEditOutlineIcon />
                  Modify
                </Grid>
              </MenuItem>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem
                onClick={handleFilteringAction("notpo")}
              >
                <Grid
                  container
                  justifyItems="center"
                >
                  <SpeakerNotesOffIcon />
                  Not PO
                </Grid>
              </MenuItem>
            </Menu>
          </Grid>
        </>
      )
    }
    if (key === "currentValue") {
      return (
        <>
          <Grid xs={1}>
            <IconButton
              onClick={handleOpenCurrentValueFilter}
              edge="end"
              color="primary"
            >
              <FilterAltIcon />
            </IconButton>
            <Menu
              anchorEl={currentValueAnchorElement}
              open={openCurrentValueFilter}
              onClose={handleCloseCurrentValueFilter}
            >
              <MenuItem
                onClick={() => false}
              >
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onInput={((event) => {
                    handleFilteringCurrentValue(event.target.value)
                  })}
                  label="Current Value Filter"
                  placeholder='Type and search Current Value'
                  variant="outlined"
                >
                </TextField>
              </MenuItem>
            </Menu>
          </Grid>
        </>
      )
    }
  }

  const PONumber = (currentValue, metadata, action) => {
    try {
      const parsedValueJSON = metadata.replace(/'/g, '"')
      const parseJSON = JSON.parse(parsedValueJSON);
      const adjustedValue = !_.isNil(parseJSON) && !_.isEmpty(parseJSON) && parseJSON["ORDER_NUMBER"]
        ? parseJSON["ORDER_NUMBER"]
        : "";
      if (!_.isNil(currentValue) && !_.isEmpty(currentValue)) {
        const parsedValue = currentValue.replace(/'/g, '"')
        const fixedValueFalse = parsedValue.replace(/False/g, "false");
        const fixedValueTrue = fixedValueFalse.replace(/True/g, "true");
        const fixedNoneValue = fixedValueTrue.replace(/None/g, "false");
        const adjustBadEscapedValue = fixedNoneValue.replace(/xa0/g, "");
        const replacedBackslash = adjustBadEscapedValue.replace(/\\/g, "");
        const adjustedNone = replacedBackslash.replace(/None/g, "false");
        try {
          const parsedData = JSON.parse(adjustedNone);
          if (parsedData) {
            const { outputs } = parsedData;
            const keys = !_.isNil(outputs) || (!_.isEmpty(outputs))
              ? Object.keys(outputs)
              : [];
            const PONumbers = keys.map((element) => {
              if (!_.isNil(outputs) && !_.isEmpty(outputs)) {
                const currentData = outputs[element]['data']
                const PONumberObject = currentData.find((variable) => PO_NUMBER_NAMES.includes(variable['Data Variables']));
                return PONumberObject['Data input'] || ""
              }
              return ""
            })
            return ((action !== "modify")
              ? adjustedValue
              : PONumbers.toString()
            )
          }
        } catch (error) {
          return ""
        }
      } else {
        return adjustedValue
      }
    } catch (e) {
      return ""
    }
  }

  const resetFilters = () => {
    const actualPage = currentPage
      ? currentPage
      : 1
    setAppliedFilters({
      "startDate": false, "endDate": false, "mailbox": false,
      "transactionId": false, "workflowId": false, "userId": false,
      "username": false, "clientId": false, "action": false, "prevValue": false,
      "currentValue": false, "createdDate": false
    });
    setAppliedFiltersValues({
      "startDate": getStartDate(), "endDate": "ALL", "mailbox": "ALL",
      "transactionId": "ALL", "workflowId": "ALL", "userId": "ALL",
      "username": "ALL", "clientId": "ALL", "action": "ALL", "prevValue": "ALL",
      "currentValue": "ALL", "createdDate": "ALL"
    })

    setStartDate(getStartDateRaw());
    setEndDate(new Date());
    setFilteredData(auditData);
    setCurrentPage(actualPage);
  }

  const getPreviousModify = async () => {
    let prevIndex = _.findLastIndex(filteredData, (item, index) => item['action'] === 'modify' && index < currentIndex);
    while (prevIndex === -1) {
      const prevPage = currentPage - 1;
      if (prevPage < 1) {
        return;
      }
      const {
        transactionId, workflowId, userId, username, clientId,
        action, prevValue, currentValue, startDate, endDate
      } = appliedFiltersValues
      const validateCheck = await auditsFilters(
        tokenValue, transactionId, workflowId, userId, username, clientId,
        action, prevValue, currentValue, startDate, endDate, appliedFiltersValues['mailbox'], prevPage, 25
      )
      if (validateCheck.detail) {
        setFilteredData([]);
        return;
      }
      const { data } = validateCheck;
      const { items, page, size, total } = data;
      prevIndex = _.findLastIndex(items, (item) => item['action'] === 'modify');
      if (prevIndex !== -1) {
        setTotalPages(Math.ceil(total / size));
        setCurrentPage(page);
        setFilteredData(items);
        handlePOJSON(items[prevIndex]['currentValue'], prevIndex, items[prevIndex]['metadata'], items[prevIndex]['prevValue'], items[prevIndex]['transactionId'])();
        return;
      }
    }
    handlePOJSON(filteredData[prevIndex]['currentValue'], prevIndex, filteredData[prevIndex]['metadata'], filteredData[prevIndex]['prevValue'], filteredData[prevIndex]['transactionId'])();
  };

  const getNextModify = async () => {
    let nextIndex = _.findIndex(filteredData, (item, index) => item['action'] === 'modify' && index > currentIndex);
    while (nextIndex === -1) {
      const nextPage = currentPage + 1;
      if (nextPage > totalPages) {
        return;
      }
      const {
        transactionId, workflowId, userId, username, clientId,
        action, prevValue, currentValue, startDate, endDate
      } = appliedFiltersValues
      const validateCheck = await auditsFilters(
        tokenValue, transactionId, workflowId, userId, username, clientId,
        action, prevValue, currentValue, startDate, endDate, appliedFiltersValues['mailbox'], nextPage, 25
      )
      if (validateCheck.detail) {
        setFilteredData([]);
        return;
      }
      const { data } = validateCheck;
      const { items, page, size, total } = data;
      nextIndex = _.findIndex(items, (item) => item['action'] === 'modify');
      if (nextIndex !== -1) {
        setTotalPages(Math.ceil(total / size));
        setCurrentPage(page);
        setFilteredData(items);
        handlePOJSON(items[nextIndex]['currentValue'], nextIndex, items[nextIndex]['metadata'], items[nextIndex]['prevValue'], items[nextIndex]['transactionId'])();
        return;
      }
    }
    handlePOJSON(filteredData[nextIndex]['currentValue'], nextIndex, filteredData[nextIndex]['metadata'], filteredData[nextIndex]['prevValue'], filteredData[nextIndex]['transactionId'])();
  }

  const getMailboxesDataCall = async (token, mailboxes, regions) => {
    // const parsedMailboxes = JSON.parse(mailboxes);
    // const data = await getMailboxesData(token, parsedMailboxes, regions);
    // const { status, payload } = data;
    // if (status === "SUCCESS") {
    //   const { items } = payload;
    //   if (items) {
    //     const selectData = items.map((element) => {
    //       return element.mailboxSelectValue
    //     })
    //     selectData.push({ label: "ALL", value: "ALL" })

    //     const validatedMailboxesDict = items.map((element) => {
    //       return { [element.mailboxName]: element.mailboxSelectValue.label }
    //     })
    //     validatedMailboxesDict.push({ "ALL": "ALL" })
    //     const unitedMailboxesDict = validatedMailboxesDict.reduce((acc, obj) => {
    //       const key = Object.keys(obj)[0];
    //       acc[key] = obj[key];
    //       return acc;
    //     }, {});
    //     setMailboxesDict(unitedMailboxesDict);
    //     setMailboxesOptions(selectData);
    //   }
    // } else {
    //   setMailboxesOptions([]);
    //   setMailboxesDict({});
    // }
  };

  useEffect(() => {
    const tokenData = localStorage.getItem(STORAGE_KEYS.TOKEN);
    const mailboxesData = localStorage.getItem(STORAGE_KEYS.MAILBOXES);
    // getMailboxesDataCall(tokenData, mailboxesData, []);
    setTokenValue(tokenData);
    setAPICallAudits(tokenData, checkTid);
  }, []);

  useEffect(() => {
    handleAPIWithFiltersCall();
  }, [appliedFiltersValues]);

  return (
    <>
      <Grid
        container
        paddingTop={3}
        justifyContent="center"
      >
        <h2>
          Audit Information
        </h2>
        {(!_.isEmpty(currentElementKeys) && !_.isNil(currentElementKeys)) && (
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="center"
            paddingBottom={1}
          >
            {checkFiltersObjValues() && (
              <Grid item xs={6}>
                <h2>
                  You have applied the following filters:
                </h2>
                <Grid
                  container
                  justifyContent="center"
                >
                  {filtersListVisualization()}
                </Grid>
              </Grid>
            )}
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={4}>
                  <Tooltip
                    title={
                      <>
                        <b>Refresh List</b>
                      </>
                    }
                    arrow
                    placement="top"
                  >
                    <Button
                      variant="contained"
                      color="success"
                      onClick={refreshList}
                    >
                      <RefreshIcon />
                    </Button>
                  </Tooltip>
                </Grid>
                <Grid item xs={4}>
                  <Tooltip
                    title={
                      <>
                        <b>Reset Filters</b>
                      </>
                    }
                    arrow
                    placement="top"
                  >
                    <Button
                      variant="contained"
                      color="success"
                      onClick={resetFilters}
                    >
                      <RestartAltIcon />
                    </Button>
                  </Tooltip>
                </Grid>
                {localStorage.getItem(STORAGE_KEYS.USER).includes("vao.world") && (
                  <Grid item xs={4}>
                    <Tooltip
                      title={
                        <>
                          <b>Download Audit Report</b>
                        </>
                      }
                      arrow
                      placement="top"
                    >
                      <Button
                        variant="contained"
                        onClick={handleDownloadAuditReport}
                      >
                        <DownloadForOfflineIcon />
                      </Button>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <TableContainer component={Paper}
        style={{
          alignItems: "center"
        }}>
        <Table aria-label="customized table"
          style={{
            alignItems: "center"
          }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell
                style={{
                  textAlign: "center"
                }}
              >
                PO Number(s)
              </StyledTableCell>
              {(!_.isEmpty(currentElementKeys) && !_.isNil(currentElementKeys))
                ? currentElementKeys.map((key, index) => {
                  if (key === "metadata") {
                    return (
                      null
                    )
                  }
                  if (key === "modifiedDate") {
                    return (
                      null
                    )
                  }
                  if (key === "transactionId") {
                    return (
                      <StyledTableCell
                        style={{
                          textAlign: "center"
                        }}
                      >
                        <Grid
                          container
                          textAlign="center"
                          alignItems="center"
                        >
                          <Grid item xs={11}>
                            TID
                          </Grid>
                          <Grid item xs={1}>
                            {checkFilters(key)}
                          </Grid>
                        </Grid>
                      </StyledTableCell>
                    )
                  }
                  if (key === "workflowId") {
                    return (
                      null
                    )
                  }
                  if (key === "userId") {
                    return (
                      null
                    )
                  }
                  if (key === "clientId") {
                    return (
                      null
                    )
                  }
                  if (key === "createdBy") {
                    return (
                      null
                    )
                  }
                  if (key === "modifiedBy") {
                    return (
                      null
                    )
                  }
                  if (key === "username") {
                    return null
                  }
                  if (key === "businessUnit") {
                    return null
                  }
                  if (key === "isPoOrder") {
                    return (null)
                  }
                  if (key === "logs") {
                    return (
                      null
                    )
                  }
                  // REGEX for spacing when camelCase
                  const parsedKey = key.replace(/([a-z])([A-Z])/g, '$1 $2');
                  const firstLetterRegex = /(\b[a-z](?!\s))/g;
                  const upperFirstLetter = parsedKey.replace(firstLetterRegex, (letter) => letter.toUpperCase());
                  return (
                    <StyledTableCell index={index}>
                      <Grid
                        container
                        textAlign="center"
                        alignItems="center"
                      >
                        <Grid item xs={11}>
                          {upperFirstLetter}
                        </Grid>
                        <Grid item xs={1}>
                          {checkFilters(key)}
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  )
                })
                : null}

              <StyledTableCell>
                Check Details
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody
            style={{
              width: "100%"
            }}
          >
            {(!_.isEmpty(filteredData) && !_.isNil(filteredData) && !_.isEmpty(currentElementKeys) && !_.isNil(currentElementKeys) && !isLoading)
              ? filteredData.map((excelValue, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell>
                      {PONumber(excelValue['prevValue'], excelValue['metadata'], excelValue['action'])}
                    </StyledTableCell>
                    {currentElementKeys.map((currentKey, innerIndex) => {
                      if (currentKey === "createdDate") {
                        return (
                          <StyledTableCell
                            style={{
                              textAlign: "center"
                            }}
                            index={innerIndex}
                          >
                            {parseDateToUserTimezone(excelValue[currentKey])}
                          </StyledTableCell>
                        )
                      }
                      if (currentKey === "metadata") {
                        return (
                          null
                        )
                      }
                      if (currentKey === "createdBy") {
                        return (
                          null
                        )
                      }
                      if (currentKey === "modifiedDate") {
                        return (
                          null
                        )
                      }
                      if (currentKey === "modifiedBy") {
                        return (
                          null
                        )
                      }
                      if (currentKey === "logs") {
                        return (
                          null
                        )
                      }
                      if (currentKey === "isPoOrder") {
                        return (null)
                      }
                      if (currentKey === "transactionId") {
                        return (
                          <StyledTableCell>
                            <Tooltip
                              title={
                                <>
                                  <b>{excelValue[currentKey]}</b>
                                </>
                              }
                              arrow
                              placement="top"
                            >
                              <IconButton
                                onClick={handleOpenTIDValue(excelValue[currentKey])}
                                edge="end"
                                color="primary"
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        )
                      }
                      if (currentKey === "workflowId") {
                        return (
                          null
                        )
                      }
                      if (currentKey === "userId") {
                        return (
                          null
                        )
                      }
                      if (currentKey === "clientId") {
                        return (
                          null
                        )
                      }
                      if (currentKey === "currentValue") {
                        if (_.isEmpty(excelValue[currentKey]) || excelValue[currentKey] === "{}" || excelValue["action"] === "approve") {
                          return (
                            <StyledTableCell
                              style={{
                                justifyContent: "center"
                              }}
                            >
                              <Grid
                                container
                                justifyContent="center"
                              >
                                --
                              </Grid>
                            </StyledTableCell>)
                        } else {
                          return (
                            <StyledTableCell>
                              <Grid
                                container
                                justifyContent="center"
                              >
                                <Tooltip
                                  title={
                                    <>
                                      <b>View</b>
                                    </>
                                  }
                                  arrow
                                  placement="top"
                                >
                                  <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={excelValue["action"] === "notifyTo_update"
                                      ? handleGenericModal(excelValue[currentKey], true)
                                      : handlePOJSON(excelValue[currentKey], index, excelValue['metadata'],
                                        excelValue['prevValue'], excelValue['transactionId'])}
                                  >
                                    <RemoveRedEyeIcon />
                                  </Button>
                                </Tooltip>

                              </Grid>
                            </StyledTableCell>
                          )
                        }
                      }
                      if (currentKey === "username") {
                        return null
                      }
                      if (currentKey === "businessUnit") {
                        return null
                      }
                      if (currentKey === "action") {
                        return (

                          <StyledTableCell>
                            <Grid container justifyContent='center'>
                              {_.get(processValuesToJSON(excelValue['metadata']), 'is_mass_approval', false) ?
                                (
                                  <Tooltip arrow placement="top">
                                    <Badge badgeContent="M.A" color="primary" style={{ padding: '8px' }}>
                                      {excelValue[currentKey]}
                                    </Badge>
                                  </Tooltip>
                                )
                                :
                                excelValue[currentKey]
                              }
                            </Grid>
                          </StyledTableCell>


                        )
                      }
                      return (
                        <StyledTableCell
                          style={{
                            justifyContent: "center",
                            textAlign: "center"
                          }}
                          index={innerIndex}>
                          {excelValue[currentKey]}
                        </StyledTableCell>
                      )
                    })}
                    <StyledTableCell>
                      <Tooltip
                        title={
                          <>
                            <b>Redirect</b>
                          </>
                        }
                        arrow
                        placement="top"
                      >

                        <Button
                          variant="contained"
                          color="success"
                          onClick={redirectWebPage(excelValue['transactionId'], excelValue['isPoOrder'], excelValue['mailbox'])}
                        >
                          <SearchIcon />
                        </Button>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })
              : null}
            {isLoading && (
              checkIfLoading()
            )}
            {(!isLoading && _.isEmpty(filteredData)) && (
              <h2>No records found.</h2>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={copiedClipboard}
        autoHideDuration={5000}
        onClose={handleCloseCopiedValue}
      >
        <Alert
          severity="success"
          icon={<CheckCircleOutlineIcon fontSize="inherit" />}
        >
          <AlertTitle>
            <strong>
              Transaction ID copied to clipboard
            </strong>
          </AlertTitle>
        </Alert>
      </Snackbar>
      <Grid
        container
        justifyContent="center"
        paddingTop={3}
        paddingBottom={5}
      >
        {<Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />}
      </Grid>
      {
        openModalView && (
          <ModalCurrPrevValues
            transactionIdValue={transactionId}
            handleCloseModal={handleCloseModalView}
            openModal={openModalView}
            POJSON={POJSON}
            previousModify={getPreviousModify}
            nextModify={getNextModify}
            totalKeys={totalKeys}
            metadata={metadata}
          />
        )
      }
      {
        openGenericModalView && (
          <GenericModalCurrPrevValues
            handleCloseModal={handleCloseGenericModalView}
            openModal={openGenericModalView}
            data={genericModalData}
            currValue={isCurrentValueGenericModal}
          />
        )
      }
    </>

  )
}

export default AuditComponent