import _ from 'lodash';

import React, { useState, useEffect } from 'react'
import {
    Card, CardContent, CardHeader, Button, Grid, Typography,
    Chip, Tooltip, IconButton,
} from '@mui/material';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { parseDateToUserTimezone } from '../../../../../utils/utils';
import { STORAGE_KEYS } from '../../../../../helpers/helpers';
import { getWorkflowsPerUser, changeWorkflowStatus } from '../../../../../APICalls';
import CustomizableConfiguration from './CustomizableConfiguration';

const WorkflowsAdmin = () => {
    const [workflowData, setWorkflowData] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [selectedCardData, setSelectedCardData] = useState(null);
    const [token, setToken] = useState(null);
    const [isCreate, setIsCreate] = useState(false);
    const [userRole, setUserRole] = useState(null);

    const callGetWorkflowsAPI = async (token) => {
        const response = await getWorkflowsPerUser(token)
            .then(response => response);
        const { status, data } = response;
        if (status === 200) {
            const adjustedPayload = data.map((element) => {
                return {
                    workflowId: element.id,
                    workflowName: element.workflow_name,
                    status: element.status,
                    config: element.payload.configuration,
                    mailboxes: element.payload.mailboxes,
                    username: element.payload.username,
                    createdBy: element.createdBy,
                    modifiedBy: element.modifiedBy
                }
            })
            setWorkflowData(adjustedPayload);
        } else {
            setWorkflowData(null);
        }
    }

    useEffect(() => {
        const tokenData = localStorage.getItem(STORAGE_KEYS.TOKEN);
        const roleData = localStorage.getItem(STORAGE_KEYS.USER_TYPE);
        setUserRole(roleData);
        setToken(tokenData);
        callGetWorkflowsAPI(tokenData);
    }, []);

    const adjustChangeStatusAPICall = async (workflowId, workflowStatus) => {
        const result = await changeWorkflowStatus(token, workflowId, workflowStatus)
            .then(response => response);
        const { status } = result;
        if (status === "SUCCESS") {
            callGetWorkflowsAPI(token);
        }
    }

    const visualizeWorkflowView = (workflowId, workflowName, username, mailboxes, config) => () => {
        setOpenModal(true);
        setSelectedCardData({
            workflowId,
            workflowName,
            config,
            mailboxes,
            username
        })
        setIsCreate(false);
    }

    const handleCloseModal = () => {
        setSelectedCardData(null);
        setOpenModal(false);
    }

    const updateWorkflowStatus = (workflowId, status) => () => {
        const changedStatus = status === "active" ? 'submitted' : 'active'
        adjustChangeStatusAPICall(workflowId, changedStatus);
    }

    const handleCreateWorkflow = () => {
        setSelectedCardData(null);
        setOpenModal(true);
        setIsCreate(true);
    }

    const refreshData = () => {
        callGetWorkflowsAPI(token);
    }

    return (
        <>
            <Grid
                container
                justifyContent="center"
                paddingBottom={3}
            >
                <h2>
                    Available Workflows
                </h2>
            </Grid>
            <Grid container justifyContent="end">
                <Grid item xs={1}>
                    <Tooltip
                        title={
                            <b>Create new Workflow</b>
                        }
                        arrow
                        placement="top"
                    >
                        <IconButton>
                            <AddCircleOutlineIcon
                                fontSize="large"
                                onClick={handleCreateWorkflow}
                                color='black'
                            />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent="space-evenly"
                spacing={3}
                paddingTop={1}
            >
                {workflowData && !_.isNil(workflowData)
                    ? workflowData.map((element) => {
                        const { workflowId, workflowName, config, mailboxes, username, createdDate, createdBy, status } = element
                        return (
                            <Grid item>
                                <Card sx={{ maxWidth: 520 }}>
                                    <CardHeader
                                        subheader={
                                            <>
                                                <AccountTreeIcon />
                                                &nbsp;
                                                <b>{workflowName}</b>
                                            </>
                                        }
                                        subheaderTypographyProps={{ color: 'black' }}
                                    />
                                    <CardContent>
                                        <Grid
                                            container
                                            justifyContent='center'
                                            alignItems='center'
                                            spacing={1}
                                        >
                                            <Grid item xs={12}>
                                                <Grid container alignItems='center'>
                                                    <Grid item xs={3}>
                                                        <Typography variant='body1'>

                                                            Mailboxes:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Typography variant='body2'>
                                                            <Grid container alignItems='center' justifyContent='center' spacing={1}>
                                                                {mailboxes.map((element) => {
                                                                    return <Grid item xs={6}>
                                                                        <Tooltip
                                                                            title={
                                                                                <>
                                                                                    <b>{element}</b>
                                                                                </>
                                                                            }
                                                                            arrow
                                                                            placement="top"
                                                                        >

                                                                            <Chip label={element} />
                                                                        </Tooltip>

                                                                    </Grid>
                                                                })}
                                                            </Grid>

                                                        </Typography>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container alignItems='center'>
                                                    <Grid item xs={3}>
                                                        <Typography variant='body1'>
                                                            Created date:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Typography variant='body2'>
                                                            {parseDateToUserTimezone(createdDate)}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container alignItems='center'>
                                                    <Grid item xs={3}>
                                                        <Typography variant='body1'>
                                                            Created by:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Typography variant='body2'>
                                                            {createdBy}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container alignItems='center'>
                                                    <Grid item xs={3}>
                                                        <Typography variant='body1'>
                                                            Current Status:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Typography variant='body2'>
                                                            <b>{status === 'active'
                                                                ? <>
                                                                    {'ENABLED'}
                                                                    &nbsp;
                                                                    <CheckIcon color='success' />
                                                                </> :
                                                                <>
                                                                    {'DISABLED'}
                                                                    &nbsp;
                                                                    <ClearIcon color='error' />
                                                                </>
                                                            }</b>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={5} paddingTop={3} alignItems='center' justifyContent='center'>
                                                    <Grid item>
                                                        <Button
                                                            variant='contained'
                                                            color="info"
                                                            disabled={userRole !== "admin"}
                                                            onClick={visualizeWorkflowView(workflowId, workflowName, username, mailboxes, config)}
                                                        >
                                                            Visualize Workflow
                                                        </Button>
                                                    </Grid><Grid item>
                                                        <Button
                                                            variant='contained'
                                                            color={status === 'active' ? 'warning' : 'success'}
                                                            onClick={updateWorkflowStatus(workflowId, status)}
                                                            disabled={userRole !== "admin"}
                                                        >
                                                            {status === 'active' ? 'Disable workflow' : 'Enable workflow'}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                {openModal && (
                                    <CustomizableConfiguration
                                        openModal={openModal}
                                        selectedCardData={selectedCardData}
                                        handleCloseModal={handleCloseModal}
                                        isCreate={isCreate}
                                        refreshData={refreshData}
                                    />
                                )}
                            </Grid>
                        )
                    })
                    : <h3>
                        There are currently no workflows to show.
                    </h3>}
            </Grid>
        </>
    )
}

export default WorkflowsAdmin;