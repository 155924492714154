import {
  Button, Grid, Typography, Drawer, Toolbar, IconButton, MenuItem,
  List, ListItem, ListItemButton, ListItemText, ListItemIcon,
} from '@mui/material';

import MuiAppBar from "@mui/material/AppBar";
import { styled, useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DataObjectIcon from '@mui/icons-material/DataObject';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import TimelineIcon from '@mui/icons-material/Timeline';
import useMediaQuery from '@mui/material/useMediaQuery';
import WebIcon from '@mui/icons-material/Web';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import { STORAGE_KEYS } from '../../../../helpers/helpers';

import HomePage from './HomePage';
import Configuration from './Configuration/Configuration';
import GeneralSettings from './Configuration/GeneralSettings';
import AuditComponent from './AuditComponent';
import MasterDataConfig from './MasterData/MasterDataConfig';
import MainDropdown from '../UtilsComponents/MainDropdown';
import handleLogout from '../../../../utils/handleLogout';
import CustomizableConfiguration from './Configuration/CustomizableConfiguration';
import OrganizationSettings from './Configuration/OrganizationSettings';
import WorkflowsAdmin from './Configuration/WorkflowsAdmin';
import UserManagement from './UserManagement/UserManagement';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    //padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth})`,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `-${drawerWidth}`,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth})`,
    marginLeft: `${drawerWidth}`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MENU_SETTINGS = ['Audit', "Organization Settings", "Workflow Configuration", "User Management", "Master Data"];

const MENU_MAP = {
  "Audit": <SupervisorAccountIcon />,
  "Workflow Configuration": <AutoFixHighIcon />,
  "Organization Settings": <CorporateFareIcon />,
  'Master Data': <DataObjectIcon />,
  "User Management": <ManageAccountsIcon />
}

const MENU_COMPONENTS = {
  'Audit': <AuditComponent />,
  "Workflow Configuration": <WorkflowsAdmin />,
  "Organization Settings": <OrganizationSettings />,
  'Master Data': <MasterDataConfig />,
  "User Management": <UserManagement />
}

const drawerWidth = '200px';

const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [username, setUsername] = useState("");
  const [userType, setUserType] = useState(null);
  const [currentComponent, setCurrentComponent] = useState(null);
  const [open, setOpen] = useState(true);
  const [vaoLogo, setVaoLogo] = useState("https://vao-open-bucket.s3.amazonaws.com/landing-page/gray-logo-vao.png");
  const isSmallScreen = useMediaQuery('(max-width:1000px)');

  const fetchImageDS1 = async () => {
    setVaoLogo("https://vao-open-bucket.s3.amazonaws.com/landing-page/gray-logo-vao.png");
  }

  const goToHome = () => {
    navigate("/home")
  }

  const goToSetings = () => {
    navigate("/sopSettings")
  }

  const goToStats = () => {
    navigate("/stats")
  }

  const goToDashboard = () => {
    navigate("/SOPHome")
  }

  /**
   * When the drawer is open, set the state of the drawer to true.
   */
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  /**
   * When the drawer is closed, set the state of the drawer to false.
   */
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onHomePage = () => {
    navigate("/SOPHome");
  }

  useEffect(() => {
    const userData = localStorage.getItem(STORAGE_KEYS.USER);
    const userTypeData = localStorage.getItem(STORAGE_KEYS.USER_TYPE);
    const homePageValue = "Audit";
    setUsername(userData);
    setUserType(userTypeData);
    setCurrentComponent(homePageValue);
    fetchImageDS1();
  }, []);

  const checkClick = (event) => {
    setCurrentComponent(event.target.outerText)
  }

  const checkCurrentMenuSettings = () => MENU_SETTINGS

  const checkCurrentMenuIcons = () => MENU_MAP

  const checkCurrentMenuComponent = () => MENU_COMPONENTS

  return (
    <Grid container>
      <Grid
        item
        xs={2}
        style={{
          alignItems: "center",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="left"
        >
          <AppBar position="fixed" open={open}>
            <Toolbar style={{ backgroundColor: "#f5f5f5", color: "black" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  mr: 2,
                  ...(open && { display: 'none' })
                }}
              >
                <MenuIcon />
              </IconButton>
              <Grid
                container
                alignItems="center"
                justifyContent="left"
              >
                <Grid item xs={3}>
                  <Button
                    variant="text"
                    color="info"
                    onClick={goToHome}
                  >
                    <img
                      src={vaoLogo}
                      style={{
                        maxWidth: isSmallScreen ? "20%" : "100%",
                        height: "auto"
                      }}
                      alt="VAO Logo"
                    >
                    </img>
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                >
                  <b>
                    Settings Page
                  </b>
                </Typography>
              </Grid>
              <Grid
                container
                alignItems="center"
                justifyContent="right"
              >
                <Grid item>
                  <MainDropdown username={username}>
                    <MenuItem onClick={goToHome} disableRipple>
                      <HomeIcon style={{ color: "#0088d1" }} />
                      Homepage
                    </MenuItem>
                    <MenuItem onClick={goToDashboard} disableRipple>
                      <WebIcon style={{ color: "#0088d1" }} />
                      Dashboard
                    </MenuItem>
                    <MenuItem onClick={goToSetings} disableRipple>
                      <SettingsIcon style={{ color: "#0088d1" }} />
                      Settings
                    </MenuItem>
                    <MenuItem onClick={goToStats} disableRipple>
                      <TimelineIcon style={{ color: "#0088d1" }} />
                      Stats
                    </MenuItem>
                    <MenuItem onClick={() => handleLogout(() => navigate('/home'))} disableRipple>
                      <ExitToAppIcon style={{ color: "#0088d1" }} />
                      <b style={{ color: "red" }}>Logout</b>
                    </MenuItem>
                  </MainDropdown>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              height: "100%",
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                backgroundColor: 'lightblue',
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader style={{
              background: "lightblue",
            }}>
              <Grid
                container
              >
                <Grid
                  item
                  xs={12}
                >
                  <div
                    style={{ float: 'right' }}
                  >
                    <IconButton
                      onClick={handleDrawerClose}
                    >
                      {
                        theme.direction === 'ltr'
                          ? <ChevronLeftIcon fontSize="large" />
                          : <ChevronRightIcon fontSize="large" />
                      }
                    </IconButton>
                  </div>
                </Grid>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  paddingTop={5}
                  height="100%"
                >
                  <List>
                    {checkCurrentMenuSettings().map((text, index) => (
                      <ListItem
                        key={text}
                        disablePadding
                        alignItems="flex-start"
                      >
                        <ListItemButton
                          onClick={checkClick}
                        >
                          <ListItemIcon>
                            <b>
                              {checkCurrentMenuIcons()[text]}
                            </b>
                          </ListItemIcon>
                          <ListItemText primary={text} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </DrawerHeader>
          </Drawer>
        </Grid>
      </Grid>
      <Main open={open}>
        <Grid
          container
          justifyContent="center"
          paddingTop={10}
          paddingLeft={2}
          paddingRight={2}
          style={{
            width: open
              ? `calc(90vw - ${drawerWidth})`
              : "100vw",
            marginLeft: open
              ? `${drawerWidth}`
              : 0,
            height: "100vh",
          }}
        >
          <Grid
            item
            xs={12}
          >
            {currentComponent && (
              checkCurrentMenuComponent()[currentComponent]
            )}
          </Grid>
        </Grid>
      </Main >
    </Grid >
  )
};

export default Home;
