import _ from 'lodash';

import React, { useState } from 'react'

import PDFViewer from '../PDFViewer/PDFViewer';
import LoadHTMLFromExcel from '../Email/LoadEmailFromExcel';
import {
  Card, Grid, IconButton, Tab, Tabs
} from '@mui/material';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import GridOnIcon from '@mui/icons-material/GridOn';
import DownloadIcon from '@mui/icons-material/Download';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AttatchmentView = ({ POPDF, excel, companyName, PONumber }) => {

  const [attatchmentValue, setAttatchmentValue] = useState(0);


  const attatchmentValidationExcel = () => {
    return !_.isEmpty(POPDF)
      ? attatchmentValue - (POPDF.length + excel.length)
      : attatchmentValue
  }

  const showValidationComponent = (isExcel) => {
    if (isExcel) {
      return !_.isEmpty(POPDF)
        ? attatchmentValue >= POPDF.length
        : true
    } else {
      return !_.isEmpty(POPDF)
        ? attatchmentValue < POPDF.length
        : false
    }

  }

  const downloadPOPDF = () => {
    // create "a" HTML element with href to file & click
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([POPDF[attatchmentValue]], {
      type: "text/plain"
    }));
    const currentDateValue = new Date();
    // Extract date and time components
    const year = currentDateValue.getFullYear();
    const month = (currentDateValue.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, so add 1
    const day = currentDateValue.getDate().toString().padStart(2, '0');
    const hours = currentDateValue.getHours().toString().padStart(2, '0');
    const minutes = currentDateValue.getMinutes().toString().padStart(2, '0');
    const seconds = currentDateValue.getSeconds().toString().padStart(2, '0');

    // Create formatted date and time strings
    const formattedDateTime = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;

    // file extension, PDF
    a.setAttribute("download", `PDF_FILE_${formattedDateTime}.pdf`);
    document.body.appendChild(a);
    a.click();
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(a);
  }

  const donwloadExcel = () => {
    const href = URL.createObjectURL(new Blob([excel[attatchmentValidationExcel()]]));
    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    // file extension, XLS for excel
    link.setAttribute('download', 'AttatchmentExcel.xls');
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  const handleChangeattatchmentValue = (event, newValue) => {
    if (event?.target?.innerText?.includes("EXCEL")) {
      setAttatchmentValue(POPDF.length + newValue);
    } else {
      setAttatchmentValue(newValue);
    }
  };

  const downloadAttatchment = () => {
    if (showValidationComponent(false) && !_.isEmpty(POPDF)) {
      return (
        <>
          <IconButton
            onClick={downloadPOPDF}
            edge="end"
            color="primary"
          >
            <DownloadOutlinedIcon />
          </IconButton>
        </>
      )
    };
    if (showValidationComponent(true) && !_.isEmpty(excel)) {
      return (
        <>
          <IconButton
            onClick={donwloadExcel}
            edge="end"
            color="primary"
          >
            <DownloadIcon />
          </IconButton>
        </>
      )
    }
  }

  const checkAttatchmentComponent = () => {
    if (showValidationComponent(false) && !_.isEmpty(POPDF)) {
      return (
        <>
          <PDFViewer
            rowFile={POPDF[attatchmentValue]}
            isInput
          />
        </>
      )
    };
    if (showValidationComponent(true) && !_.isEmpty(excel)) {
      return (
        <>
          <LoadHTMLFromExcel
            rowFile={excel[attatchmentValidationExcel()]}
          />
        </>
      )
    }
  }

  return (
    <div>
      <Grid
        container
      >
        <Grid item xs={12} sm={12} md={12}>
          <Tabs
            value={attatchmentValue}
            onChange={handleChangeattatchmentValue}
            variant="scrollable"
            scrollButtons
          >
            {!_.isEmpty(POPDF) && (
              POPDF.map((element, key) => (
                <Tab
                  icon={<PictureAsPdfIcon />}
                  iconPosition="start"
                  label={
                    <span>
                      {`PDF # ${key + 1}`}
                      {downloadAttatchment()}
                    </span>}
                  {...a11yProps(0)}
                />
              ))
            )}
            {!_.isEmpty(excel) && (
              excel.map((element, key) => (
                <Tab
                  icon={<GridOnIcon />}
                  iconPosition="start"
                  label={
                    <span>
                      {`Excel # ${key + 1}`}
                      {downloadAttatchment()}
                    </span>
                  }
                  {...a11yProps(1)}
                />
              ))
            )}
          </Tabs>
          <Card
            className='cardStyle'
            style={{ height: '700px', overflowY: 'auto' }}
          >
            {checkAttatchmentComponent()}
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default AttatchmentView